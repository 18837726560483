<template>
    <div class="container key-benefits">
      <div class="content">
        <h3 class="cs-textstyle-page-title title">Key Benefits</h3>
  
        <div v-for="(benefit, index) of benefits" :key="benefit.title">
          <div v-if="index%2===0" class="row first">
            <div class="left text-class">
            <div class="cs-textstyle-section-heading">{{ benefit.title }}</div>
            <div class="cs-textstyle-paragraph">{{ benefit.text }}</div>
          </div>
          <div class="item"><img :src="benefit.img" class="img-class"/></div>
          </div>
          <div v-else class="row">
            <div class="item"><img :src="benefit.img" class="img-class"/></div>
            <div class="right text-class">
              <div class="cs-textstyle-section-heading">{{ benefit.title }}</div>
              <div class="cs-textstyle-paragraph">{{ benefit.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Money from '@/assets/benefit_cost.png';
  import Time from '@/assets/benefit_time.png';
  import Sleep from '@/assets/benefit_sleep.png';
  
  export default {
    data() {
      return {
        benefits: [
          {
            title: 'Save Money',
            text: 'Efficient Actions are typically 30-50% cheaper than their AWS equivalents. For example, our Transcode plugin is 50% cheaper than AWS MediaConvert.',
            img: Money,
          },
          {
            title: 'Save Time',
            text: 'Efficient Actions can be configured in minutes via API or via our user-friendly dashboard. This improves reliability, and saves thousands of dollars in development and testing time.',
            img: Time,
          },
          {
            title: 'Save Sleep',
            text: 'Don\'t re-invent the wheel. Efficient Actions cover a wide range of use-cases, are rigorously tested, and come with a 99.9% uptime SLA by default. Stress less, sleep more.',
            img: Sleep,
          },
        ],
      };
    },
  };
  </script>
  <style scoped>
  .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    margin: 50px 0px 0px 0px;
  }
  .left{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 50%;
    gap: 15px
  }
  .right{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 15px;
    text-align: left;
  }
  .text-class{
    text-align: left;
    padding: 20px;
  }
  .title{
    text-align: center;
  }
  .item{
    width: 50%;
  }
  .img-class{
      width: 100%
    }
  /* Mobile size or smaller */
  @media (max-width: 576px) {
    .row{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
    .first{
      display: flex;
      flex-direction: column-reverse;
    }
    .left{
      width: 100%
    }
    .right{
      width: 100%
    }
    .item{
      width: 60%;
    }
    .img-class{
      width: 100%
    }
    .text-class {
    text-align: center;
    }
  }
  
  </style>
  