<template>
    <div class="container jumbotron">
      <div class="content">
        <div class="jumbotron_text-holder">
          <h1 class="cs-textstyle-page-title">
            Give your data superpowers
          </h1>
          <h3 class="cs-textstyle-section-heading">
            Simple, fast and cost-effective Efficient Actions
          </h3>
          <app-request-access-link>
            <cs-button
              class="jumbotron__request-button"
              fill="outline"
              corners="rounded"
              >Request Access</cs-button
            >
          </app-request-access-link>
        </div>
        <div class="jumbotron__image-holder">
          <img
            class="jumbotron__background-image"
            :src="cloudImage"
            alt="graphic of bucket and clouds"
          />
        </div>
      </div>
    </div>
  </template>
  <script>
  import AppRequestAccessLink from '@/components/RequestAccessLink.vue';
  
  const cloudImage = require('@/assets/featured.svg');
  
  export default {
    components: {
      AppRequestAccessLink,
    },
    data() {
      return {
        cloudImage,
      };
    },
  };
  </script>
  <style scoped>
  .jumbotron {
    background-color: #f4f6ff;
  }
  .jumbotron .content {
    display: flex;
    flex-direction: column-reverse;
  
  }
  
  /* Text side */
  .jumbotron_text-holder {
    flex: 1;
    align-self: center;
    text-align: center;
    margin: 16px auto;
  }
  .jumbotron_text-holder h1 {
    color: var(--cs-primary-dark);
  }
  .jumbotron_text-holder h3 {
    color: var(--cs-gray-07);
    margin-top: 8px;
    margin-bottom: 40px;
  }
  /* Image side */
  .jumbotron__image-holder {
    flex: 1;
    align-self: center;
    width: 50%;
  }
  .jumbotron__background-image {
    width: 100%;
  }
  
  @media (min-width: 576px) {
    .jumbotron .content {
      flex-direction: row;
    }
    .jumbotron_text-holder {
      flex: 1;
      align-self: flex-end;
      margin: initial;
      text-align: left;
    }
    .jumbotron__image-holder {
      align-self: flex-end;
      margin-bottom: -64px;
      margin-left: 40px;
    }
  }
  
  @media (min-width: 1200px) {
    .jumbotron_text-holder {
      align-self: center;
    }
  }
  </style>
  