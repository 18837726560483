<template>
    <div class="container bp-header">
      <div class="content bp-header__content">
        <img :src="logo" />
        <div>
          <app-request-access-link>
            <cs-button fill="outline" corners="rounded">Request Access</cs-button>
          </app-request-access-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import AppRequestAccessLink from '@/components/RequestAccessLink.vue';
  
  const logo = require('@/assets/efficient-action-top.svg');
  
  export default {
    components: {
      AppRequestAccessLink,
    },
    data() {
      return {
        logo,
      };
    },
  };
  </script>
  
  <style scoped>
  .bp-header {
    height: 90px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
  }
  .bp-header__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bp-header__content div {
    display: flex;
    gap: 20px;
  }
  @media (max-width: 576px) {
    .bp-header {
      height: 150px;
    }
    .bp-header__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }
  </style>
  