import Vue from 'vue';
import App from './App.vue';
import router from './router';

import 'cs-components';
import 'cs-components/dist/csuite.css';

import './style.css';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
