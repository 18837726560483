<template>
    <div class="container">
      <div class="content">
          <h3 class="title cs-textstyle-page-title">Featured Actions</h3>
          <div class="row">
            <div class="items">
            <div v-for="plugin of plugins" :key="plugin.title" class="item-row">
              <div class="img-background">
                <img :src="plugin.img" class="plugin-img"/></div>
              <div class="paragraph-text">
                <div class="cs-textstyle-section-heading">{{ plugin.title }}</div>
                <div class="cs-textstyle-paragraph">{{ plugin.text }}</div>
              </div>
            </div>
            </div>
            <div class="screenshot">
              <img :src="pluginsImg" class="screenshot__img"/>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import recognition from '@/assets/icons8-image (1) 1.svg';
  import hls from '@/assets/Transcode.svg';
  import cc from '@/assets/cs-icons-caption-filled.svg';
  import pluginsImg from '@/assets/plugins.png';
  
  export default {
    data() {
      return {
        pluginsImg,
        plugins: [
          {
            title: 'Generate Captions',
            text: 'The Generate Captions action generates an srt caption file for an audio or video.',
            img: cc,
          },
          {
            title: 'Generate Image from Text',
            text: 'The Generate Image from Text action generates an image based on text provided by user using AI.',
            img: recognition,
          },
          {
            title: 'Transcode to HLS',
            text: 'The Transcode to HLS action transcodes a video to HLS format.',
            img: hls,
          },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .item-row{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: flex-start;
    gap: 20px
  }
  .img-background{
    background: #223AAC;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
  }
  .img-background img {
    width: 24px;
    height: 24px;
  }
  
  .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .items{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 190px;
  }
  .screenshot{
    width: 50%;
    background: #142572;
  }
  .screenshot__img{
    width: 100%;
    margin-left: -40px;
    margin-top: 10px;
    margin-bottom: 9.45px
  }
  .container{
   background: #F3F6FF;
  }
  .title{
    text-align: center;
    margin-bottom: 40px;
  }
  .paragraph-text{
    display: flex;
    flex-direction: column;
    gap: 10px
  }
  @media (max-width: 576px) {
    .row{
      flex-direction: column-reverse;
    }
    .items{
      width: 100%;
      padding-right: 0px;
    }
    .screenshot{
      width: 100%;
      margin-bottom: 20px;
      background: transparent;
    }
  .screenshot__img{
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px
  }
  }
  </style>
  