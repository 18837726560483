<template>
    <div class="container footer__background">
      <div class="content">
        <div class="footer__content">
          <div class="footer__left">
            <img class="footer__logo" :src="logo" />
            <div class="footer__legal">
              <a
                class="cs-textstyle-paragraph-bold"
                target="_blank"
                href="/legal/privacy.html"
                >Privacy Policy</a
              >
              <a
                class="cs-textstyle-paragraph-bold"
                target="_blank"
                href="/legal/terms.html"
                >Terms &amp; Conditions</a
              >
            </div>
            <div class="footer__copyright cs-textstyle-paragraph">
              &#169; 2023 efficientactions.com
            </div>
          </div>
          <div class="footer__right">
            <a
              class="footer__address"
              target="_blank"
              href="https://www.google.com/maps/place/Centennial+Tower,+Singapore/@1.2924313,103.8565685,17z"
            >
              <i class="cs-icons-marker" />
              <div class="footer__address-lines cs-textstyle-paragraph">
                <div class="footer__address-top">{{ address1 }}</div>
                <div class="footer__address-bottom">{{ address2 }}</div>
              </div>
            </a>
            <a
              class="footer__email cs-textstyle-paragraph-bold"
              href="mailto:hello@bucketplus.com"
            >
              <div>hello@efficientcloud.com</div>
            </a>
            <div class="footer__social">
              <a
                v-for="social in socials"
                :key="social.id"
                :href="social.link"
                target="_blank"
              >
                <img :src="social.icon" :alt="`icon for ${social.id}`" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  const logo = require('@/assets/efficient-actions.svg');
  
  const facebookIcon = require('@/assets/cs-icons-facebook-filled.svg');
  const linkedinIcon = require('@/assets/cs-icons-linkedin-filled.svg');
  const twitterIcon = require('@/assets/cs-icons-twitter-filled.svg');
  
  export default {
    data() {
      return {
        logo,
        address1: '#17/18 Centennial Tower,',
        address2: 'Singapore 039190',
        socials: [
          {
            id: 'facebook',
            icon: facebookIcon,
            link: 'https://www.facebook.com/efficientcloud',
          },
          {
            id: 'linkedin',
            icon: linkedinIcon,
            link: 'https://www.linkedin.com/company/efficientcloud',
          },
          {
            id: 'twitter',
            icon: twitterIcon,
            link: 'https://www.twitter.com/efficient_cloud',
          },
        ],
      };
    },
  };
  </script>
  <style scoped>
  .footer__background {
    background-color: var(--cs-gray-06);
  }
  .footer__content {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: var(--cs-gray-00);
    gap: 20px;
  }
  .footer__content > * {
    text-align: center;
  }
  
  /* Left side */
  .footer__logo {
    width: 206px;
    margin-bottom: 20px;
  }
  .footer__legal a {
    color: var(--cs-gray-00);
    text-decoration: none;
    margin-right: 16px;
  }
  .footer__copyright {
    margin-top: 4px;
  }
  
  /* Right side */
  .footer__address {
    color: var(--cs-gray-00);
    text-decoration: none;
  }
  .footer__address i {
    font-size: 36px;
  }
  .footer__email {
    display: block;
    color: var(--cs-gray-00);
    text-decoration: none;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .footer__social a {
    margin-right: 24px;
    margin-left: 24px;
  }
  
  @media (min-width:576px) {
    .footer__content {
      flex-direction: row;
      align-items: center;
    }
    .footer__left {
      text-align: left;
    }
    .footer__right {
      text-align: right;
    }
    .footer__social a {
      margin-left: 36px;
      margin-right: 0;
    }
    .footer__email {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .footer__address {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  </style>
  